const regex = {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/,
    password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
    url: /^(https?|ftp):\/\/(([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*\.)+[a-zA-Z]{2,}|((25[0-5]|2[0-4][\d]|[01]?[\d][\d]?)\.){3}(25[0-5]|2[0-4][\d]|[01]?[\d][\d]?)|(\[[0-9a-fA-F:]+\]))(:\d{1,5})?(\/[-a-zA-Z\d%_.~+:@]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[-a-zA-Z\d_]*)?$/,
    // url: /^((https||http):\/\/.)[-a-zA-Z0-9@:%._+~#=/]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_+`'.~#?&/=]*)$/,
    youtubeUrl:  /^https?:\/\/(www\.)?(youtube\.com|youtu\.be)(\/.*)?$/,
    twitterUrl: /^https?:\/\/(www\.)?(twitter\.com|x\.com)(\/.*)?$/,
    instagramUrl: /^https?:\/\/(www\.)?instagram\.com(\/.*)?$/,
    discordUrl: /^https?:\/\/(www\.)?discord(\.gg|\.com)?(\/.*)?$/,
    embedLink: /^https:\/\/www\.youtube\.com\/embed\/[a-zA-Z0-9_-]+(\?.*)?$/,
    // eslint-disable-next-line no-useless-escape
    username: /^[a-zA-Z0-9!@#$%^&*()_+-=<>?/\|{}\[\],.;':"\\`~]*$/,
    number : /^[0-9]*$/ ,
    maximumTwoDecimals: /^\d*\.?\d{0,2}$/
}
export default regex